<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-2"> 
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateStartModel" label="Start Date" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2"> 
                                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateEndModel" label="End Date" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2"> 
                                <v-autocomplete v-model="vehicleModel" :items="vehicleLists" default="" item-value="value" item-text="text" label="Vehicle" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-autocomplete>
                            </div>
                            <div class="col-lg-2"> 
                                <v-autocomplete v-model="statusModel" :items="statusLists" default="" item-value="value" item-text="text" label="Status" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-autocomplete>
                            </div>
                            <div class="col-lg-2"> 
                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                            <div class="col-lg-12">
                                <v-tabs-items v-model="tab">
                                    <v-tab-item value="tab-1">
                                        <v-data-table :headers="headers" :items="itemList" class="elevation-1" page-count="5" :loading="loadingDatatableDetail" dense>
                                            <template v-slot:top>
                                                <v-toolbar flat color="white">
                                                    <div class="row">
                                                        <div class="col-12 text-right">
                                                            <v-btn class="mt-3" color="green" elevation="2" small rounded @click="newTrip()" dark>NEW TRIP</v-btn>
                                                        </div>
                                                    </div>
                                                </v-toolbar>
                                            </template>
                                            <template v-slot:[`item.action`]="{ item }">
                                                <v-menu location="bottom" offset-y>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon class="ml-2" v-bind="attrs" v-on="on">
                                                            mdi-chevron-right
                                                        </v-icon>
                                                    </template>
                                                    <v-list density="compact">
                                                        <v-list-item>
                                                            <v-list-item dense @click="showDetail()">Detail</v-list-item>
                                                        </v-list-item>
                                                        <v-list-item>
                                                            <v-list-item dense @click="showDetail()">Delete</v-list-item>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </template>
                                        </v-data-table>
                                    </v-tab-item>
                                    <v-tab-item value="tab-2">
                                        <v-data-table :headers="headersDetail" :items="itemListDetail" class="elevation-1" page-count="5" :loading="loadingDatatableDetail" hide-default-footer dense>
                                            <template v-slot:top>
                                                <v-toolbar flat color="white">
                                                    <div class="row">
                                                        <div class="col-6 text-left">
                                                            <v-btn class="mt-3" color="green" elevation="2" small rounded @click="backToTab1()" dark><v-icon left dark>mdi-arrow-left</v-icon>Back</v-btn>
                                                        </div>
                                                        <div class="col-6 text-right">
                                                            <v-btn class="mt-3" color="blue" elevation="2" small rounded @click="backToTab1()" dark>New Visit</v-btn>
                                                        </div>
                                                    </div>
                                                </v-toolbar>
                                            </template>
                                            <template v-slot:[`item.action`]="{ item }">
                                                <v-menu location="bottom" offset-y>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon class="ml-2" v-bind="attrs" v-on="on">
                                                            mdi-chevron-right
                                                        </v-icon>
                                                    </template>
                                                    <v-list density="compact">
                                                        <v-list-item>
                                                            <v-list-item dense @click="showDetail()">Delete</v-list-item>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </template>
                                        </v-data-table>
                                    </v-tab-item>
                                </v-tabs-items>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <v-dialog v-model="newTripDialog" max-width="1280px">
                <div class="card elevation-5">
                    <div class="card-body">
                        asdas
                    </div>
                </div>
            </v-dialog>

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'Surya Maju Sukses',
                disabled: false,
                href: '/admin/sms',
                },
                {
                text: 'Activity',
                disabled: false,
                href: '#',
                },
                {
                text: 'Sales Trip',
                disabled: true,
                href: '#',
                },
            ],
            dateStartModal: false,
            dateStartModel: null,
            dateEndModal: false,
            dateEndModel: null,
            vehicleModel: null,
            vehicleLists: [
                { text: 'Kantor', value: 'kantor' },
                { text: 'Pribadi', value: 'pribadi' },
            ],
            statusModel: null,
            statusLists: [
                { text: 'Approved', value: 'approved' },
                { text: 'Not Approved', value: 'not_approved' },
            ],
            headers: [
                { text: 'Date', value: 'date', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Trip ID', value: 'trip_id', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Vehicle', value: 'vehicle', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Vehicle No.', value: 'vehicle_no', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Start Dest.', value: 'start_dest', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Total Dest.', value: 'total_des', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Total KM', value: 'total_km', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Status', value: 'stat', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: '', value: 'action', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
            ],
            itemList: [
                { date: '2025-01-01', trip_id: 'TRIP-2025-01-01', vehicle: 'Kantor', vehicle_no: 'B 1234 AK', start_dest: 'Jakarta', total_des: 2, total_km: "67 KM", stat: 'Approved' },
                { date: '2025-01-02', trip_id: 'TRIP-2025-01-02', vehicle: 'Kantor', vehicle_no: 'B 1234 AK', start_dest: 'Jakarta', total_des: 1, total_km: "35 KM", stat: 'Approved' },
                { date: '2025-01-03', trip_id: 'TRIP-2025-01-03', vehicle: 'Pribadi', vehicle_no: 'B 4321 OP', start_dest: 'Jakarta', total_des: 1, total_km: "15 KM", stat: 'Approved' },
                { date: '2025-01-04', trip_id: 'TRIP-2025-01-04', vehicle: 'Pribadi', vehicle_no: 'B 4321 OP', start_dest: 'Jakarta', total_des: 1, total_km: "17 KM", stat: 'Approved' },
                { date: '2025-01-05', trip_id: 'TRIP-2025-01-05', vehicle: 'Pribadi', vehicle_no: 'B 4321 OP', start_dest: 'Jakarta', total_des: 2, total_km: "80 KM", stat: 'Approved' },
                { date: '2025-01-06', trip_id: 'TRIP-2025-01-06', vehicle: 'Kantor', vehicle_no: 'B 1234 AK', start_dest: 'Jakarta', total_des: 3, total_km: "120 KM", stat: 'Not Approved' },
                { date: '2025-01-07', trip_id: 'TRIP-2025-01-07', vehicle: 'Kantor', vehicle_no: 'B 1234 AK', start_dest: 'Jakarta', total_des: 3, total_km: "210 KM", stat: 'Not Approved' },
                { date: '2025-01-08', trip_id: 'TRIP-2025-01-08', vehicle: 'Kantor', vehicle_no: 'B 1234 AK', start_dest: 'Jakarta', total_des: 1, total_km: "18 KM", stat: 'Not Approved' }
            ],
            headersDetail: [
                { text: 'Date', value: 'date', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Trip ID', value: 'trip_id', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Customer', value: 'cust', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Address', value: 'address', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'City', value: 'city', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Remark', value: 'remark', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: '', value: 'action', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
            ],
            itemListDetail: [
                { date: '2025-01-01', trip_id: 'TRIP-2025-01-01', cust: 'Bpk. Supri', address: 'Jl. Kebangsaan 45, Bogor', city: 'Bogor', remark: 'Kunjungan dan penawaran produk' },
                { date: '2025-01-01', trip_id: 'TRIP-2025-01-01', cust: 'Bpk. Gandhi', address: 'Jl. Merdeka 16, Bekasi', city: 'Bekasi', remark: 'Kunjungan' },
                
            ],
            tab: 'tab-1',
            newTripDialog: false,
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        initialize(){
            

        },

        async getData(){
            
        },

        async showDetail(){
            
            this.tab = 'tab-2'

        },

        backToTab1(){
            
            this.tab = 'tab-1'

        },

        newTrip(){
            
            this.newTripDialog = true

        }

    },
    watch: {

        
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }

</style>